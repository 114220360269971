import React, { Component } from "react"
import styled from "styled-components"
import Iframe from "./iframe"
import { colors } from "./siteVars"
import { getUser } from "./handle-auth"
import popupAlert from "./popup-alert"
import Insta from "./insta"
import Contentbox from "./contentbox"
import Button from "./button"

const StyledSingleProgram = styled.div`
  text-align: left !important;
  h1 {
    margin-top: 0;
  }
  .single-program-excercise {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    position: relative;
    margin-bottom: 3rem;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    /* &:after {
      position: absolute;
      content: "";
      bottom: -1rem;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: ${colors.mediumGrey};
    } */
    .single-program-excercise-title-container {
      width: 70%;
      grid-column: 1 / 3;
      @media (max-width: 768px) {
        grid-column: 1 /2;
      }
      h3 {
        /* margin: 0 0 3rem 0; */
        margin: 1rem 0;
      }
    }
    p {
      margin: 0;
    }
    .repset-container {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      .repset {
        background: ${colors.black};
        color: ${colors.white};
        padding: 1rem;
        margin: 0;
        margin-left: 1rem;
        border-radius: 0 1rem 1rem 1rem;
      }
    }
  }
  position: relative;
  .export-button-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    .export-button {
      width: 100%;
      /* padding-right: 3rem; */
      text-align: left;
    }
    .arrow-down {
      width: 15px;
      position: absolute;
      top: 20px;
      right: 1rem;
      transition: 200ms ease-in-out;
    }
    .additional-export-buttons {
      button {
        display: block;
        width: 100%;
        text-align: left;
      }
      /* flex-direction: column; */
      max-height: 0;
      /* width: 0; */
      overflow: hidden;
      transition: 200ms ease-in-out;
    }
    &.active {
      .additional-export-buttons {
        max-height: 10rem;
        /* width: initial; */
      }
      .arrow-down {
        transform: rotateX(180deg);
      }
    }
  }
  #urlField {
    /* display: none; */
    opacity: 0;
    position: absolute;
    z-index: -100000;
    pointer-events: none;
  }
  .single-program-image-hidden {
    opacity: 0;
    position: absolute;
    z-index: -100000;
    pointer-events: none;
  }
`

export default class SingleProgram extends Component {
  state = {
    isAdmin: false,
  }
  componentDidMount() {
    const checkUser = async () => {
      const user = await getUser()
      if (user) {
        this.setState({ permissions: user.permissions })
      }
    }
    checkUser()
  }

  toggleExportButtons = () => {
    const buttonContainer = document.querySelector(".export-button-container")
    buttonContainer.classList.toggle("active")
  }

  copyLink = () => {
    const urlField = document.querySelector("#urlField")
    urlField.select()
    urlField.setSelectionRange(0, 99999)
    document.execCommand("copy")
    popupAlert("Copied to clipboard!")
  }

  exportAsPDF = () => {
    if (typeof window !== `undefined`) {
      const jsPDF = require("jspdf")
      const doc = new jsPDF("p", "pt", "a4")
      const pageWidth = 595.28
      const pageHeight = 841.89

      //Logo init
      const logoImg = new Image()
      logoImg.src = "/images/logo_PDF.png"

      //Add program title
      doc.setFontSize(30)
      doc.text(20, 40, this.props.program.title)

      //Add logo
      doc.addImage(logoImg, "PNG", 480, 10, 104, 26)

      //Add page nr
      let pageNr = 1
      doc.setFontSize(10)
      doc.text(550, pageHeight - 20, "Sida " + pageNr)

      //Set initial top spacing
      let space = 90

      //Map excercises
      this.props.program.program.forEach((excercise, index) => {
        const img = document.querySelectorAll(".single-program-image-hidden")[
          index
        ]

        //Set image properties
        let tempImgWidth = pageWidth
        let tempImgHeight = (img.height / img.width) * pageWidth
        img.width = tempImgWidth
        img.height = tempImgHeight

        //Check if pagebreak
        if (space + (img.height - 20) / 2 + 40 > pageHeight) {
          //Add page
          doc.addPage()

          //Set headers
          doc.text(490, 20, "Teamgym planner")
          pageNr++
          doc.text(550, pageHeight - 10, "Sida " + pageNr)
          space = 60
        }

        //Add excercise title
        doc.setFontSize(20)
        doc.text(20, space, excercise.title)

        //Add rep set boxes
        doc.setDrawColor("#000")
        doc.roundedRect(pageWidth - 90, space - 20, 70, 30, 10, 10, "F")
        doc.roundedRect(pageWidth - 170, space - 20, 70, 30, 10, 10, "F")
        doc.setTextColor("#FFF")
        doc.setFontSize(15)
        doc.text(pageWidth - 80, space, "Reps: " + excercise.rep)
        doc.text(pageWidth - 160, space, "Sets: " + excercise.set)
        doc.setTextColor("#000")

        //Add excercise image
        doc.addImage(
          img,
          "JPEG",
          20,
          space + 20,
          (img.width - 20) / 2,
          (img.height - 20) / 2
        )

        //Add excercise description
        if (excercise.comment) {
          doc.setFontSize(10)
          const lines = doc.splitTextToSize(
            excercise.description,
            pageWidth / 2 - 60
          )
          doc.text(pageWidth / 2 + 20, space + 30, lines)

          //Add excercise comment
          const commentLines = doc.splitTextToSize(
            excercise.comment,
            pageWidth / 2 - 60
          )
          doc.setFontType("bold")
          doc.text(
            pageWidth / 2 + 20,
            (img.height - 20) / 4 + space + 30,
            "Comment:"
          )
          doc.setFontType("italic")
          doc.text(
            pageWidth / 2 + 20,
            (img.height - 20) / 4 + space + 40,
            commentLines
          )
        }
        doc.setFontType("normal")
        //Draw excercise divider line
        space += (img.height - 20) / 2 + 80
        doc.setDrawColor("#c7c7c7")
        doc.line(40, space - 40, pageWidth - 80, space - 40)
      })

      //Save PDF
      doc.save(`${this.props.program.title}.pdf`)
    }
  }

  render() {
    return (
      <StyledSingleProgram>
        {/* {this.state.permissions === "admin" ? ( */}
        <div className="export-button-container">
          <input
            id="urlField"
            type="text"
            readOnly
            value={window.location.href}
          />
          <Button
            className="export-button"
            onClick={() => {
              this.toggleExportButtons()
            }}
          >
            Export
            <img
              className="arrow-down"
              src={`/images/arrow-down.png`}
              alt="arrow-down"
            />
          </Button>
          <div className="additional-export-buttons">
            <Button
              className="export-button-options"
              onClick={() => {
                this.toggleExportButtons()
                this.copyLink()
              }}
            >
              Copy link
            </Button>
            <Button
              className="export-button-options"
              onClick={() => {
                this.toggleExportButtons()
                this.exportAsPDF()
              }}
            >
              Export PDF
            </Button>
            {/* <Button className="export-button-options">
                Exportera som JPG
              </Button> */}
          </div>
        </div>
        {/* ) : null} */}
        <Contentbox>
          <div className="single-program-container" id="singleProgram">
            <h1>{this.props.program.title}</h1>
            {this.props.program.program.map(excercise => (
              <div className="single-program-excercise" key={excercise._id}>
                <div className="single-program-excercise-title-container">
                  <h3>{excercise.title}</h3>
                </div>
                {/* <p>Svårighetsgrad: {excercise.difficulty}</p> */}
                <div className="repset-container">
                  <p className="repset rep">Reps: {excercise.rep}</p>
                  <p className="repset set">Sets: {excercise.set}</p>
                </div>
                <div>
                  <img
                    src={excercise.image}
                    alt={excercise.title}
                    width="100%"
                    className="single-program-image"
                  />
                  {excercise.video ? <Iframe>{excercise.video}</Iframe> : null}
                  {excercise.instaVideo ? (
                    <Insta link={excercise.instaVideo} />
                  ) : null}
                </div>
                <div>
                  <p>
                    <strong>Categories: </strong>
                    {excercise.topCat +
                      ", " +
                      excercise.middleCat +
                      ", " +
                      excercise.bottomCat}
                  </p>
                  <br />
                  {excercise.comment ? (
                    <>
                      <p>
                        <strong>Comment: </strong>
                        {excercise.comment}
                      </p>
                      <br />
                    </>
                  ) : null}
                  <p>{excercise.description}</p>
                </div>
                <img
                  src={excercise.image}
                  alt={excercise.title}
                  width="100%"
                  className="single-program-image-hidden"
                />
              </div>
            ))}
          </div>
        </Contentbox>
      </StyledSingleProgram>
    )
  }
}
