import React, { Component } from "react"
import styled from "styled-components"
import axios from "axios"
import Loader from "../components/loader"
import { colors, db } from "../components/siteVars"
import Layout from "../components/layout"
import SingleProgram from "../components/single-program"

const StyledViewProgram = styled.div`
  .loader-container {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default class ViewProgram extends Component {
  state = {
    program: {},
    loading: true,
  }

  componentDidMount() {
    axios
      .get(
        db.lambda +
          "programs/" +
          this.props.location.href.split("/")[
            this.props.location.href.split("/").length - 1
          ]
      )
      .then(res => {
        console.log(res.data)
        this.setState({
          program: res.data,
          loading: false,
        })
      })
      .catch(err => console.log("Error: " + err))
  }

  render() {
    return (
      <Layout>
        <StyledViewProgram>
          {this.state.loading ? (
            <div className="loader-container">
              <Loader dualRing color={colors.darkGrey} />
            </div>
          ) : (
            <SingleProgram program={this.state.program} />
          )}
        </StyledViewProgram>
      </Layout>
    )
  }
}
