import React, { Component } from "react"
import axios from "axios"

export default class Insta extends Component {
  state = {
    video: "",
    error: false,
  }

  componentDidMount() {
    const link = this.props.link
    const id = link.match(/.*\/(.*)\/(.*)$/)
    if (id) {
      console.log(id[1])
      axios
        .get("https://www.instagram.com/p/" + id[1] + "/?__a=1")
        .then(res => {
          this.setState({
            video: res.data.graphql.shortcode_media.video_url,
          })
        })
        .catch(err => console.log(err))
    } else {
      this.setState({
        error: true,
      })
    }
  }

  render() {
    return (
      <div
        style={{
          margin: "1rem 0",
        }}
      >
        {!this.state.error ? (
          <video src={this.state.video} controls width="100%" /> //eslint-disable-line
        ) : null}
      </div>
    )
  }
}
